<template>
  <div style="background-color: #fff">
    <titleBox
      name="关于旷真"
      imgUrl="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/title.png"
    ></titleBox>
    <van-tabs v-model="active" animated sticky swipeable>
      <van-tab
        v-for="(item, index) in activeList"
        :title="item"
        :key="index"
        style="background-color: #fff"
      >
        <!-- 内容 {{ index }} -->
        <jianjie v-show="item == '旷真简介'"></jianjie>
        <memorabilia v-show="item == '旷真大事记'"></memorabilia>
        <culture v-show="item == '旷真文化'"></culture>
        <territory v-show="item == '旷真版图'"></territory>
        <viewpoint v-show="item == '旷真观点'"></viewpoint>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import titleBox from "../../components/titleBox/index";
import jianjie from "./jianjie.vue";
import memorabilia from "./memorabilia.vue";
import culture from "./culture.vue";
import territory from "./territory.vue";
import viewpoint from "./viewpoint.vue";
export default {
  components: { titleBox, jianjie, memorabilia, culture, territory, viewpoint },
  data() {
    return {
      active: 0,
      activeList: [
        "旷真简介",
        "旷真大事记",
        "旷真文化",
        "旷真观点",
        "旷真版图",
      ],
      // "旷真架构",
    };
  },
  methods: {},
};
</script>

<style>
.van-tab--active {
  color: #ab0000 !important;
}
.van-tabs__line {
  background-color: #ab0000 !important;
}
</style>
