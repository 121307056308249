var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#fff"}},[_c('titleBox',{attrs:{"name":"旷真知产","imgUrl":"../../assets/about/title.png"}}),_c('div',{staticClass:"main"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"serviceIdea kuangzhenAdvantage",attrs:{"id":"newintellectualProperty"}},[_vm._m(6),_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"bigBox",on:{"click":function($event){return _vm.toMsgPage(item.id)}}},[_c('div',{staticClass:"header",style:('background-image: url(' + item.img + ')')}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"msg"},[_vm._v(" "+_vm._s(item.summary)+" ")]),_vm._m(7,true)])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadMoreFlag),expression:"loadMoreFlag"}],staticClass:"loadMore",on:{"click":_vm.loadMoreNews}},[_vm._v(" 加载更多... ")])],2)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zhishichanquanBox",attrs:{"id":"zhishichanquanBox"}},[_c('h2',{staticClass:"titleBox"},[_c('img',{staticClass:"numberImg",attrs:{"src":"https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/01.png","alt":""}}),_vm._v(" 知识产权维权产品 "),_c('i',{staticClass:"redIcon"})]),_c('p',{staticClass:"contenMsg"},[_vm._v(" 旷真知产以“精益法律服务”与企业进行合作，采取“零成本、全垫资、零风险”的全风险代理模式，企业商标、版权、专利维权不需要支付任何费用，以帮助客户“肃清市场”为宗旨，为客户解决企业维权中出现的侵权行为隐秘、侵权事实模糊、维权成本高、预算不足、侵权地域广等问题，帮助企业用法律手段维护自己的利益，实现快速肃清市场，真正做到零成本且全面高效地打击侵权行为。 ")]),_c('div',{staticClass:"twoBoxFather"},[_c('div',{staticClass:"twoBox"},[_c('div',{staticClass:"number"},[_vm._v("30")]),_c('div',{staticClass:"content"},[_vm._v("20家直营分所覆盖 30个城市")])]),_c('div',{staticClass:"twoBox"},[_c('div',{staticClass:"number"},[_vm._v("300")]),_c('div',{staticClass:"content"},[_vm._v("服务客户总量 300余家")])])]),_c('div',{staticClass:"twoBoxFather"},[_c('div',{staticClass:"twoBox"},[_c('div',{staticClass:"number"},[_vm._v("100")]),_c('div',{staticClass:"content"},[_vm._v("调查人数100余人")])]),_c('div',{staticClass:"twoBox"},[_c('div',{staticClass:"number"},[_vm._v("300")]),_c('div',{staticClass:"content"},[_vm._v("诉讼团队300余人")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"serviceGroup",attrs:{"id":"serviceGroup"}},[_c('h2',{staticClass:"titleBox"},[_c('img',{staticClass:"numberImg",attrs:{"src":"https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/02.png","alt":""}}),_vm._v(" 服务群体 "),_c('i',{staticClass:"redIcon"})]),_c('div',{staticClass:"msgTitle"},[_vm._v("商标行业维权法律服务产品")]),_c('div',{staticClass:"twoBoxFather threeBoxFather"},[_c('div',{staticClass:"twoBox threeBox"},[_c('div',{staticClass:"number"},[_vm._v("30")]),_c('div',{staticClass:"content"},[_vm._v("行业领域")])]),_c('div',{staticClass:"twoBox threeBox"},[_c('span',{staticClass:"number"},[_vm._v("30")]),_c('p',{staticClass:"content"},[_vm._v("服务地区")])]),_c('div',{staticClass:"twoBox threeBox"},[_c('span',{staticClass:"number"},[_vm._v("300")]),_c('p',{staticClass:"content"},[_vm._v("服务客户总量")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"serviceIdea",attrs:{"id":"serviceIdea"}},[_c('h2',{staticClass:"titleBox"},[_c('img',{staticClass:"numberImg",attrs:{"src":"https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/03.png","alt":""}}),_vm._v(" 服务理念 "),_c('i',{staticClass:"redIcon"})]),_c('div',{staticClass:"msgTitle"},[_vm._v("客户中心")]),_c('p',{staticClass:"contenMsg"},[_vm._v(" 旷真始终秉持“客户至上”的服务理念，立足市场，将商标、版权、专利维权服务产品化，标准化、流程化，全方位提高客户体验。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"serviceMode",attrs:{"id":"serviceMode"}},[_c('h2',{staticClass:"titleBox"},[_c('img',{staticClass:"numberImg",attrs:{"src":"https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/04.png","alt":""}}),_vm._v(" 服务模式 "),_c('i',{staticClass:"redIcon"})]),_c('img',{staticClass:"serviceModeImg",attrs:{"src":"https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/serviceMode.png","alt":""}}),_c('div',{staticClass:"msg"},[_vm._v("全风险代理模式与客户风险共担，盈利共存。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"serviceProcess",attrs:{"id":"serviceProcess"}},[_c('h2',{staticClass:"titleBox"},[_c('img',{staticClass:"numberImg",attrs:{"src":"https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/05.png","alt":""}}),_vm._v(" 服务流程 "),_c('i',{staticClass:"redIcon"})]),_c('div',{staticClass:"processBox"},[_c('div',{staticClass:"leftBox"},[_c('img',{staticClass:"LeftIcon",attrs:{"src":"https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/investigation.png","alt":""}}),_vm._v(" 调查 ")]),_c('div',{staticClass:"rightBox"},[_c('span',[_vm._v("线上线下定期 \\ 主动监测排查 \\ 全面治理维权")])])]),_c('div',{staticClass:"processBox"},[_c('div',{staticClass:"leftBox"},[_c('img',{staticClass:"LeftIcon",attrs:{"src":"https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/tort.png","alt":""}}),_vm._v(" 侵权 ")]),_c('div',{staticClass:"rightBox"},[_c('span',[_vm._v("一次授权 \\ 全国实施 \\ 方便快捷")])])]),_c('div',{staticClass:"processBox"},[_c('div',{staticClass:"leftBox"},[_c('img',{staticClass:"LeftIcon",attrs:{"src":"https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/implementation.png","alt":""}}),_vm._v(" 实施 ")]),_c('div',{staticClass:"rightBox"},[_c('span',[_vm._v("民事诉讼 \\ 行政查处 \\ 刑事打击")])])]),_c('div',{staticClass:"processBox"},[_c('div',{staticClass:"leftBox"},[_c('img',{staticClass:"LeftIcon",attrs:{"src":"https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/report.png","alt":""}}),_vm._v(" 报告 ")]),_c('div',{staticClass:"rightBox"},[_c('span',[_vm._v("专属客服 \\ 定期汇报 \\ 随时响应")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"serviceIdea kuangzhenAdvantage",attrs:{"id":"kuangzhenAdvantage"}},[_c('h2',{staticClass:"titleBox"},[_c('img',{staticClass:"numberImg",attrs:{"src":"https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/06.png","alt":""}}),_vm._v(" 旷真优势 "),_c('span',{staticClass:"showMore"},[_c('i',{staticClass:"right"})])]),_c('div',{staticClass:"msgTitle"},[_vm._v("建材行业应收账款法律服务产品")]),_c('img',{staticClass:"advantage",attrs:{"src":"https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/scale.png","alt":""}}),_c('img',{staticClass:"advantage",attrs:{"src":"https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/zhenzhiying.png","alt":""}}),_c('img',{staticClass:"advantage",attrs:{"src":"https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/productization.png","alt":""}}),_c('img',{staticClass:"advantage",attrs:{"src":"https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/specialization.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"titleBox"},[_c('img',{staticClass:"numberImg",attrs:{"src":"https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/07.png","alt":""}}),_vm._v(" 知产新知 "),_c('i',{staticClass:"redIcon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"watchMsg"},[_vm._v("查看详细解读 "),_c('i',{staticClass:"arrowIcon"})])
}]

export { render, staticRenderFns }