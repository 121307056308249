import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
// 登录验证
export default new Vuex.Store({
    state: {
        isright: false,
        indexCardStatus: 1,
        myCardStatus: ''
    },
    mutations: {
        
    }
})