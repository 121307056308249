<template>
  <div>
    <titleBox name="旷真应收款" imgUrl="../../assets/zhichanBg.jpg"></titleBox>
    <!-- 法律服务产品 -->
    <div
      class="itemBox"
      style="
        background-image: url(https://f.kzspp.com/cms/new/theme/kz/apphtml/image/intellectualProperty/zhishichanquanBG.png);
      "
      id="yewu"
    >
      <h2 class="titleBox">
        <img
          src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/01.png"
          class="numberImg"
          alt=""
        />
        法律服务产品
        <i class="redIcon"></i>
      </h2>
      <p class="contenMsg">
        经多年实践，我们领航建材行业法律服务，开创了国内“法律服务产品化”的先河。通过流程化运营对法律服务内容进行标准化分解，从服务成本、效率、质量进行全方位控制，目前已向市场推出一款成熟产品
        ——“建材行业应收款管理”。
        未来，基于法律服务产品化、标准化、流程化的优势，我们将在其它专业领域按照精益法律服务的理念继续深耕，推出更多符合市场需求的法律服务产品。
      </p>
    </div>
    <!-- 服务成果 -->
    <div class="itemBox" id="yewu">
      <h2 class="titleBox">
        <img
          src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/02.png"
          class="numberImg"
          alt=""
        />
        服务成果<span class="redColor"></span>
        <i class="redIcon"></i>
      </h2>
      <div class="contentBox">
        <div class="topBox">
          <div class="leftBox">
            <span class="num redColor"
              ><countTo
                :startVal="0"
                separator=""
                :endVal="900"
                :duration="2000"
              ></countTo
              >亿+</span
            >
            <p class="numTitle">累计帮助客户收回应收款</p>
          </div>
          <div class="leftBox">
            <span class="num redColor"
              ><countTo
                separator=""
                :startVal="0"
                :endVal="50000"
                :duration="2000"
              ></countTo
              >件+</span
            >
            <p class="numTitle">代理各类应收款案件</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 服务群体 -->
    <div
      class="itemBox"
      style="
        background-image: url(https://f.kzspp.com/cms/new/theme/kz/apphtml/image/intellectualProperty/serviceIdeaBg.png);
      "
      id="yewu"
    >
      <h2 class="titleBox">
        <img
          src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/03.png"
          class="numberImg"
          alt=""
        />
        服务群体
        <i class="redIcon"></i>
      </h2>
      <h3 class="contenLittleTitle" style="font-size: 0.4rem">
        应收款管理的服务群体
      </h3>
      <div class="contentBox">
        <div class="topBox">
          <div class="leftBox">
            <span class="num redColor"
              ><countTo
                separator=""
                :startVal="0"
                :endVal="2400"
                :duration="2000"
              ></countTo
              >+</span
            >
            <p class="numTitle">混凝土供应商</p>
          </div>
          <div class="leftBox">
            <span class="num redColor"
              ><countTo
                separator=""
                :startVal="0"
                :endVal="1800"
                :duration="2000"
              ></countTo
              >+</span
            >
            <p class="numTitle">建材物资租赁商</p>
          </div>
        </div>
        <div class="topBox">
          <div class="leftBox">
            <span class="num redColor"
              ><countTo
                separator=""
                :startVal="0"
                :endVal="950"
                :duration="2000"
              ></countTo
              >+</span
            >
            <p class="numTitle">建材物资贸易商</p>
          </div>
          <div class="leftBox">
            <span class="num redColor"
              ><countTo
                :startVal="0"
                separator=""
                :endVal="250"
                :duration="2000"
              ></countTo
              >+</span
            >
            <p class="numTitle">其他行业应收款客户</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 服务模式 -->
    <div class="itemBox" id="yewu">
      <h2 class="titleBox">
        <img
          src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/04.png"
          class="numberImg"
          alt=""
        />
        服务模式
        <i class="redIcon"></i>
      </h2>
      <h3
        class="contenLittleTitle"
        style="font-size: 0.4rem; text-align: center; padding: 0"
      >
        应收款法律服务产品
      </h3>
      <h3
        class="contenLittleTitle"
        style="color: #c70209; text-align: center; padding: 0"
      >
        <img src="./serviceMode.png" alt="" />
      </h3>
      <div style="text-align: center">
        <div class="fwmsBg">全风险代理模式</div>
      </div>
      <!-- <div class="threeBoxFather">
        <div class="threeBox">
          <span class="littleTitle">风雨同舟</span>
          <p class="msg">先行垫资与 客户共担风 险</p>
        </div>
        <div class="threeBox">
          <span class="littleTitle">钱程无忧</span>
          <p class="msg">最大程度保 证客户债权 本金</p>
        </div>
        <div class="threeBox">
          <span class="littleTitle">提前兑换</span>
          <p class="msg">进入法律文 书生效阶段 可提前回款</p>
        </div>
      </div> -->
    </div>
    <!-- 服务流程 -->
    <div class="itemBox" id="yewu">
      <h2 class="titleBox">
        <img
          src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/05.png"
          class="numberImg"
          alt=""
        />
        服务流程
        <i class="redIcon"></i>
      </h2>
      <img src="./yinshoukuan/05.jpg" style="width: 100%" alt="" />
      <!-- <h3 class="contenLittleTitle" style="font-size: 0.4rem">
        事前、事中、事后全过程服务
      </h3> -->
      <!-- <div class="liuchengBox">
        <div class="littleBox">① 签订合同</div>
        <div class="littleBox">② 法律咨询</div>
        <div class="littleBox">③ 办案计划</div>
        <div class="littleBox">④ 法律流程</div>
        <div class="littleBox">⑤ 安全回款</div>
      </div> -->
    </div>
    <!-- 应收款全套体系化管理方案 -->
    <div class="itemBox bg" id="yewu">
      <h2 class="titleBox">
        <img
          src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/06.png"
          class="numberImg"
          alt=""
        />
        应收款全套体系化管理方案
        <i class="redIcon"></i>
      </h2>
      <div class="yskBox">
        <ul class="yskUlList">
          <li class="qttxCss">
            <img src="./qietu/06-1.png" alt="" />
            应收款全流程管理
          </li>
          <li class="qttxCss">
            <img src="./qietu/06-3.png" alt="" />
            债权收购服务
          </li>
          <li class="qttxCss">
            <img src="./qietu/06-5.png" alt="" />
            非诉服务
          </li>
        </ul>
        <ul class="yskUlList">
          <li class="qttxCss">
            <img src="./qietu/06-2.png" alt="" />
            诉讼金融服务
          </li>
          <li class="qttxCss">
            <img src="./qietu/06-4.png" alt="" />
            债权流通服务
          </li>
          <li class="qttxCss">
            <img src="./qietu/06-6.png" alt="" />
            快速回款服务
          </li>
        </ul>
      </div>
    </div>
    <!-- 服务优势 -->
    <div class="itemBox" id="yewu">
      <h2 class="titleBox">
        <img
          src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/07.png"
          class="numberImg"
          alt=""
        />
        服务优势
        <i class="redIcon"></i>
      </h2>
      <img src="./yinshoukuan/07.jpg" style="width: 100%" alt="" />
      <!-- <h3 class="contenLittleTitle">
        <img
          src="https://f.kzspp.com/cms/new/theme/kz/apphtml/image/youshi.png"
          alt=""
          style="width: 60%"
        />
      </h3> -->
    </div>
    <!-- 服务理念 -->
    <div class="itemBox" id="yewu">
      <h2 class="titleBox">
        <img
          src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/08.png"
          class="numberImg"
          alt=""
        />
        服务理念
        <i class="redIcon"></i>
      </h2>
      <h3
        class="contenLittleTitle"
        style="
          color: #c70209;
          font-size: 0.8rem;
          font-family: FZQKBYSJW--GB1-0, FZQKBYSJW--GB1;
        "
      >
        <!-- 客户中心 -->
        <img
          src="http://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/kehuzhongxing.png"
          alt=""
          style="width: 30%"
        />
      </h3>
      <p class="contenMsg" style="color: #999; margin-bottom: 20px">
        旷真始终秉持“客户中心”的服务理念
      </p>
      <p class="contenMsg">
        立足市场，发挥专业、经验及品牌优势，为客户合法权益保驾护航
      </p>
    </div>
    <!-- 应收款全套体系化管理方案 -->
    <div class="itemBox" id="yewu">
      <h2 class="titleBox">
        <img
          src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/09.png"
          class="numberImg"
          alt=""
        />
        客户服务
        <i class="redIcon"></i>
      </h2>

      <div class="yskBox">
        <ul class="yskUlList">
          <li>
            <img
              src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/pc/yinshoukuan/qiwei.png"
              alt=""
              style="width: 80%"
            />
            <p>
              添加客户服务企微<br />
              获得专属法律服务
            </p>
          </li>
        </ul>
        <ul class="yskUlList">
          <li>
            <img
              src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/pc/yinshoukuan/xiaochengxu.png"
              alt=""
              style="width: 80%"
            />
            <p>扫描查询案件进度</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="itemBox" id="yewu">
      <h2 class="titleBox">
        <img src="../../assets/10.png" class="numberImg" alt="" />
        业务<span class="redColor">风采</span>
        <i class="redIcon"></i>
      </h2>
      <!-- <h3 class="contenLittleTitle" style="font-size: 0.4rem">
        建材行业应收账款法律服务产品
      </h3> -->
      <div
        class="bigBox"
        v-for="(item, index) in list"
        :key="index"
        @click="toMsgPage(item.id)"
      >
        <div class="header" :style="'background-image: url(' + item.img + ')'">
          <!-- <div class="isHot">Hot</div> -->
        </div>
        <div class="title">{{ item.title }}</div>
        <div class="msg">
          {{ item.summary }}
        </div>
        <div class="watchMsg">查看详细解读 <i class="arrowIcon"></i></div>
      </div>

      <div class="loadMore" v-show="loadMoreFlag" @click="loadMoreNews">
        加载更多...
      </div>
    </div>
  </div>
</template>

<script>
import titleBox from "../../components/titleBox/index";
import countTo from "vue-count-to";
import req from "../../utils/index";
export default {
  components: { titleBox, countTo },
  data() {
    return {
      list: [],
      loadMoreFlag: true,
      pageNum: 1,
    };
  },
  methods: {
    toMsgPage(id) {
      this.$router.push("/newsMsg?id=" + id);
    },
    loadMoreNews() {
      this.pageNum = Number(this.pageNum) + 1;
      this.search();
    },
    search() {
      req("post", "api/base/articlelist", {
        Cid: "28",
        Page: this.pageNum,
        Limit: 5,
      }).then((res) => {
        this.list = this.list.concat(res.data);
        if (res.data.length < 5) {
          this.loadMoreFlag = false;
        }
      });
    },
  },
  created() {
    this.search();
  },
};
</script>

<style lang="scss" scoped>
.redColor {
  color: #ab0000;
}
.arrowIcon {
  position: relative;
  font-size: 0.1rem;
  top: -0.15rem;
  color: #fa6e23;
}
.itemBox {
  // width: 95%;
  margin: 0rem auto;
  padding: 0 0 0.5rem 0;
  background-color: #fff;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .titleBox {
    width: 95%;
    margin: auto;
    padding-top: 0.5rem;
    margin-bottom: 0.2rem;
    position: relative;
    height: 1rem;
    line-height: 1rem;
    font-size: 0.5rem;

    .numberImg {
      position: relative;
      top: 0.05rem;
      width: 0.5rem;
      height: 0.5rem;
    }
  }
  .contenMsg {
    width: 87%;
    margin: 0 auto;
    box-sizing: border-box;
    padding-left: 0.2rem;
    line-height: 0.6rem;
    font-size: 0.35rem;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #666666;
  }
  .contenLittleTitle {
    width: 95%;
    margin: 0.3rem auto;
    padding-left: 0.6rem;
    box-sizing: border-box;
    color: #333333;
    font-size: 0.5rem;
    img {
      width: 60%;
    }
    // line-height: 1rem;
  }
  .fwmsBg {
    position: relative;
    display: inline-block;
    margin: -0.2rem auto 0;
    box-sizing: border-box;
    // padding-left: 0.8rem;
    overflow: hidden;
  }
  .fwmsBg:after {
    position: absolute;
    bottom: 0.1rem;
    left: 0rem;
    width: 80%;
    height: 0.2rem;
    content: "";
    background: linear-gradient(226deg, rgba(218, 0, 0, 0) 0%, #da0000 100%);
    opacity: 0.3;
  }
  .contentBox {
    width: 95%;
    margin: auto;
    padding-left: 0.6rem;
    .topBox {
      display: flex;
      margin-bottom: 0.5rem;
      .leftBox {
        flex: 1;
        padding-left: 0.3rem;
        text-align: center;
      }
      .rightBox {
        flex: 1;
      }
      .num {
        position: relative;
        font-family: DINAlternate-Bold, DINAlternate;
        font-size: 0.7rem;
        font-weight: 700;
      }
      .numTitle {
        margin: 0;
        color: #999;
        font-size: 0.35rem;
        font-family: PingFangSC-Regular, PingFang SC;
      }
    }
  }
  .threeBoxFather {
    width: 95%;
    margin: auto;
    overflow: hidden;
    padding: 0.5rem 0 0 0.5rem;
    box-sizing: border-box;
    text-align: center;

    .threeBox {
      position: relative;
      float: left;
      width: 33%;
      // margin-left: 0.3rem;
      // background-color: #fff;
      height: 3.3rem;
      background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/receivables/04BG.png);
      background-size: 100%;
      background-repeat: no-repeat;
      // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
      border-radius: 0.2rem;
      padding: 0.7rem 0 0.12rem 0;
      text-align: center;

      .littleTitle {
        position: relative;
        color: #333333;
        font-weight: 700;
        font-size: 0.4rem;
        z-index: 999;
      }

      .littleTitle::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.2rem;
        content: "";
        background: linear-gradient(
          226deg,
          rgba(218, 0, 0, 0) 0%,
          #da0000 100%
        );
        opacity: 0.3;
      }

      .msg {
        width: 75%;
        margin: 0.3rem auto;
        text-align: left;
        font-size: 0.35rem;
        color: #666666;
      }
    }
  }
  .liuchengBox {
    width: 95%;
    padding-left: 0.6rem;
    margin: auto;
    overflow: hidden;
    .littleBox {
      position: relative;
      float: left;
      padding: 0.2rem 0.7rem 0.2rem 0.3rem;
      color: #333333;
      margin: 0 0.2rem 0.4rem 0;
      border-radius: 0.2rem;
      background-image: url(https://f.kzspp.com/cms/new/theme/kz/apphtml/image/intellectualProperty/leftBoxBackground.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .littleBoxIcon {
        position: absolute;
        right: -0.4rem;
        top: 50%;
        transform: translateY(-50%);
        color: #da0000;
        box-shadow: 2px 4px 6px 0px rgba(218, 0, 0, 0.21);
      }
    }
  }
  .kzyoushi {
    display: flex;
    width: 95%;
    margin: 0.3rem auto;
    padding-left: 0.7rem;
    .littleBox {
      position: relative;
      flex: 1;
      padding-left: 0.6rem;
      font-size: 0.4rem;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #666666;
      img {
        position: absolute;
        left: 0.1rem;
        width: 0.4rem;
        top: 50%;
        transform: translateY(-50%);
      }
      span {
        font-weight: 700;
        color: #333333;
      }
    }
  }
  .youshiImg {
    display: block;
    width: 95%;
    margin: 0.3rem auto;
    padding-left: 0.5rem;
    box-sizing: border-box;
  }
  .bigBox {
    width: 90%;
    margin: 0.5rem auto;
    padding-left: 0.4rem;
    box-sizing: border-box;

    .header {
      position: relative;
      width: 100%;
      height: 5rem;
      // background-color: #f7f4f5;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/itemBG.png);
      .isHot {
        position: absolute;
        left: 0;
        top: 0;
        background-color: #fa6e23;
        padding: 0.05rem 0.3rem;
        font-family: PingFangSC-Regular, PingFang SC;
        border-radius: 0px 0px 6px 0px;
        color: #ffffff;
      }
    }

    .title {
      margin: 0.2rem 0;
      color: #707070;
      font-size: 0.35rem;
      font-weight: 700;
    }

    .msg {
      font-size: 0.3rem;
      color: #666666;
      margin-bottom: 0.1rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .watchMsg {
      color: #999;
      font-size: 0.3rem;
    }
  }
  .arrowIcon {
    position: relative;
    display: inline-block;
    top: -0.1rem;
    width: 12px;
    height: 7px;
    background-size: 100% 100%;
    background-image: url(http://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/watchMsgICon.png);
  }
}
.have02BG {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/receivables/02BG.png);
}

.yskBox {
  width: 80%;
  margin: 0.5rem auto;
  display: flex;
  .yskUlList {
    flex: 1;
    li {
      margin: 0.2rem 0 0.2rem -0.1rem;
      font-size: 0.25rem;
      text-align: center;
    }
    .qttxCss {
      position: relative;
      width: 95%;
      margin: 0.2rem auto;
      background-color: #ab0000;
      border-radius: 20px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      img {
        position: relative;
        top: 0.05rem;
        width: 0.3rem;
        margin-right: 0.2rem;
      }
    }
  }
}
.redIcon {
  position: relative;
  display: inline-block;
  top: -0.3rem;
  width: 8px;
  height: 7px;
  background-size: 100% 100%;
  background-image: url(http://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/redIcon.png);
}
.loadMore {
  padding: 0.3rem 0;
  text-align: center;
}
.bg {
  background-image: url("./qietu/bg.png");
}
</style>
