<template>
  <div style="background: #fff">
    <titleBox name="旷真知产" imgUrl="../../assets/about/title.png"></titleBox>
    <!-- <van-tabs v-model="active" sticky swipeable>
      <van-tab
        v-for="(item, index) in activeList"
        :title="item.title"
        :key="index"
        style="background-color: #fff"
      >
      </van-tab>
    </van-tabs> -->
    <!-- 主体部分 -->
    <div class="main">
      <!-- 知识产权维权产品 -->
      <div class="zhishichanquanBox" id="zhishichanquanBox">
        <h2 class="titleBox">
          <img
            src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/01.png"
            class="numberImg"
            alt=""
          />
          知识产权维权产品

          <i class="redIcon"></i>
        </h2>
        <p class="contenMsg">
          旷真知产以“精益法律服务”与企业进行合作，采取“零成本、全垫资、零风险”的全风险代理模式，企业商标、版权、专利维权不需要支付任何费用，以帮助客户“肃清市场”为宗旨，为客户解决企业维权中出现的侵权行为隐秘、侵权事实模糊、维权成本高、预算不足、侵权地域广等问题，帮助企业用法律手段维护自己的利益，实现快速肃清市场，真正做到零成本且全面高效地打击侵权行为。
        </p>
        <div class="twoBoxFather">
          <div class="twoBox">
            <div class="number">30</div>
            <div class="content">20家直营分所覆盖 30个城市</div>
          </div>
          <div class="twoBox">
            <div class="number">300</div>
            <div class="content">服务客户总量 300余家</div>
          </div>
        </div>
        <div class="twoBoxFather">
          <div class="twoBox">
            <div class="number">100</div>
            <div class="content">调查人数100余人</div>
          </div>
          <div class="twoBox">
            <div class="number">300</div>
            <div class="content">诉讼团队300余人</div>
          </div>
        </div>
      </div>
      <!-- 服务群体 -->
      <div class="serviceGroup" id="serviceGroup">
        <h2 class="titleBox">
          <img
            src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/02.png"
            class="numberImg"
            alt=""
          />
          服务群体
          <i class="redIcon"></i>
        </h2>
        <div class="msgTitle">商标行业维权法律服务产品</div>
        <div class="twoBoxFather threeBoxFather">
          <div class="twoBox threeBox">
            <div class="number">30</div>
            <div class="content">行业领域</div>
          </div>
          <div class="twoBox threeBox">
            <span class="number">30</span>
            <p class="content">服务地区</p>
          </div>
          <div class="twoBox threeBox">
            <span class="number">300</span>
            <p class="content">服务客户总量</p>
          </div>
        </div>
      </div>
      <!-- 服务理念 -->
      <div class="serviceIdea" id="serviceIdea">
        <h2 class="titleBox">
          <img
            src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/03.png"
            class="numberImg"
            alt=""
          />
          服务理念
          <i class="redIcon"></i>
        </h2>
        <div class="msgTitle">客户中心</div>
        <p class="contenMsg">
          旷真始终秉持“客户至上”的服务理念，立足市场，将商标、版权、专利维权服务产品化，标准化、流程化，全方位提高客户体验。
        </p>
      </div>
      <!-- 服务模式 -->
      <div class="serviceMode" id="serviceMode">
        <h2 class="titleBox">
          <img
            src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/04.png"
            class="numberImg"
            alt=""
          />
          服务模式
          <i class="redIcon"></i>
        </h2>
        <img
          src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/serviceMode.png"
          alt=""
          class="serviceModeImg"
        />
        <div class="msg">全风险代理模式与客户风险共担，盈利共存。</div>
      </div>
      <!-- 服务流程 -->
      <div class="serviceProcess" id="serviceProcess">
        <h2 class="titleBox">
          <img
            src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/05.png"
            class="numberImg"
            alt=""
          />
          服务流程
          <i class="redIcon"></i>
        </h2>
        <div class="processBox">
          <div class="leftBox">
            <img
              src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/investigation.png"
              alt=""
              class="LeftIcon"
            />
            调查
          </div>
          <div class="rightBox">
            <span>线上线下定期 \ 主动监测排查 \ 全面治理维权</span>
          </div>
        </div>
        <div class="processBox">
          <div class="leftBox">
            <img
              src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/tort.png"
              alt=""
              class="LeftIcon"
            />
            侵权
          </div>
          <div class="rightBox">
            <span>一次授权 \ 全国实施 \ 方便快捷</span>
          </div>
        </div>
        <div class="processBox">
          <div class="leftBox">
            <img
              src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/implementation.png"
              alt=""
              class="LeftIcon"
            />
            实施
          </div>
          <div class="rightBox">
            <span>民事诉讼 \ 行政查处 \ 刑事打击</span>
          </div>
        </div>
        <div class="processBox">
          <div class="leftBox">
            <img
              src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/report.png"
              alt=""
              class="LeftIcon"
            />
            报告
          </div>
          <div class="rightBox">
            <span>专属客服 \ 定期汇报 \ 随时响应</span>
          </div>
        </div>
      </div>
      <!-- 旷真优势 -->
      <div class="serviceIdea kuangzhenAdvantage" id="kuangzhenAdvantage">
        <h2 class="titleBox">
          <img
            src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/06.png"
            class="numberImg"
            alt=""
          />
          旷真优势
          <span class="showMore"><i class="right"></i></span>
        </h2>
        <div class="msgTitle">建材行业应收账款法律服务产品</div>
        <img
          src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/scale.png"
          alt=""
          class="advantage"
        />
        <img
          src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/zhenzhiying.png"
          alt=""
          class="advantage"
        />
        <img
          src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/productization.png"
          alt=""
          class="advantage"
        />
        <img
          src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/specialization.png"
          alt=""
          class="advantage"
        />
      </div>
      <!-- 知产新知 -->
      <div class="serviceIdea kuangzhenAdvantage" id="newintellectualProperty">
        <h2 class="titleBox">
          <img
            src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/07.png"
            class="numberImg"
            alt=""
          />
          知产新知
          <i class="redIcon"></i>
        </h2>
        <div
          class="bigBox"
          v-for="(item, index) in list"
          :key="index"
          @click="toMsgPage(item.id)"
        >
          <div
            class="header"
            :style="'background-image: url(' + item.img + ')'"
          >
            <!-- <div class="isHot">Hot</div> -->
          </div>
          <div class="title">{{ item.title }}</div>
          <div class="msg">
            {{ item.summary }}
          </div>
          <div class="watchMsg">查看详细解读 <i class="arrowIcon"></i></div>
        </div>

        <!-- <div class="thisBoxShowMore">查看更多</div> -->

        <div class="loadMore" v-show="loadMoreFlag" @click="loadMoreNews">
          加载更多...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleBox from "../../components/titleBox/index";
import req from "../../utils/index";
export default {
  components: { titleBox },
  data() {
    return {
      list: [],
      boxid: "zhishichanquanBox",
      topBoxLeftScroll: {
        topzhishichanquanBox: 0,
        topserviceGroup: 100,
        topserviceIdea: 200,
        topserviceMode: 300,
        topserviceProcess: 400,
        topkuangzhenAdvantage: 500,
      },
      active: 0,
      activeList: [
        { title: "知识产权维权产品", id: "zhishichanquanBox" },
        { title: "服务群体", id: "serviceGroup" },
        { title: "服务理念", id: "serviceIdea" },
        { title: "服务模式", id: "serviceMode" },
        { title: "服务流程", id: "serviceProcess" },
        { title: "旷真优势", id: "kuangzhenAdvantage" },
        { title: "知产新知", id: "newintellectualProperty" },
      ],
      loadMoreFlag: true,
      pageNum: 1,
    };
  },
  methods: {
    toMsgPage(id) {
      this.$router.push("/newsMsg?id=" + id);
    },
    toMaodian(id) {
      this.boxid = id;
    },
    loadMoreNews() {
      this.pageNum = Number(this.pageNum) + 1;
      this.search();
    },
    search() {
      req("post", "api/base/articlelist", {
        Cid: "3",
        Page: this.pageNum,
        Limit: 5,
      }).then((res) => {
        this.list = this.list.concat(res.data);
        if (res.data.length < 5) {
          this.loadMoreFlag = false;
        }
      });
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log(scrollTop, "滚动距离");
      if (scrollTop > 631) {
        this.active = 0;
      }
      if (scrollTop > 631) {
        this.active = 1;
      }
      if (scrollTop > 870) {
        this.active = 2;
      }
      if (scrollTop > 1080) {
        this.active = 3;
      }
      if (scrollTop > 1207) {
        this.active = 4;
      }
      if (scrollTop > 1458) {
        this.active = 5;
      }
      if (scrollTop > 1962) {
        this.active = 6;
      }
    },
  },
  created() {
    this.search();
  },
  // 滚动监听
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
  },
  // 滚动重置
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #333;
}
.contenMsg {
  width: 95%;
  margin: 0.3rem auto 0.4rem;
  box-sizing: border-box;
  padding-left: 0.7rem;
  line-height: 0.5rem;
  font-size: 0.35rem;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #666666;
}
.zhishichanquanBox {
  width: 100%;
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/zhishichanquanBG.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: 0.01rem;
  margin-top: 0.2rem;

  .twoBoxFather {
    padding-left: 0.9rem;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 0rem;

    .twoBox {
      float: left;
      width: 50%;
      // text-align: center;
    }
  }
}

.titleBox {
  width: 95%;
  margin: auto;
  position: relative;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.5rem;

  .numberImg {
    position: relative;
    top: 0.05rem;
    width: 0.5rem;
    height: 0.5rem;
  }
  .arrowIcon {
    position: relative;
    top: 0.05rem;
    color: #fa6e23;
  }
}
.number {
  position: relative;
  display: inline-block;
  font-size: 0.9rem;
  color: #ab0000;
  font-weight: 400;
  font-family: dincond;
  margin-bottom: -1.3rem;
}

.number::after {
  position: absolute;
  right: -0.4rem;
  // top: 0;
  bottom: 45%;
  content: "+";
  font-size: 0.5rem;
}

.content {
  padding-right: 0.5rem;
  box-sizing: border-box;
  font-size: 0.3rem;
  color: #999999;
  margin: 0.1rem 0 0.3rem;
}
.msgTitle {
  font-weight: 700;
  padding: 0.3rem 0 0.3rem 0.9rem;
  font-size: 0.4rem;
}

.serviceGroup {
  padding: 0.5rem 0 0.1rem 0;
  background-color: #fff;

  .threeBoxFather {
    padding-left: 0.9rem;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 1rem;

    .threeBox {
      position: relative;
      float: left;
      width: 30%;
      // margin-left: 1rem;
    }
  }
}

.serviceIdea {
  width: 100%;
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/serviceIdeaBg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0.3rem 0;

  // padding-bottom: 1rem;
  .msg {
    font-size: 0.3rem;
    color: #666666;
    padding: 0 0.5rem 0 0.7rem;
    box-sizing: border-box;
  }
}

.serviceMode {
  width: 100%;
  padding: 0.5rem 0;

  .serviceModeImg {
    display: block;
    width: 50%;
    padding-left: 0.8rem;
    margin: 0.2rem 0;
  }

  .msg {
    position: relative;
    display: inline-block;
    color: #333333;
    z-index: 999;
    padding-left: 0.8rem;
  }

  .msg::after {
    position: absolute;
    bottom: 0;
    left: 0.8rem;
    width: 100%;
    height: 0.2rem;
    content: "";
    background: linear-gradient(226deg, rgba(218, 0, 0, 0) 0%, #da0000 100%);
    opacity: 0.3;
  }
}

.serviceProcess {
  width: 100%;
  padding: 0.3rem 0;
  background-color: #fff;

  .processBox {
    position: relative;
    margin: 0.2rem 0;
    padding-left: 0.9rem;
    padding-right: 0.2rem;
    box-sizing: border-box;

    .leftBox {
      display: inline-block;
      width: 24%;
      height: 0.8rem;
      line-height: 0.8rem;
      margin-right: 1%;
      text-align: center;
      box-sizing: border-box;
      background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/leftBoxBackground.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .LeftIcon {
        position: relative;
        top: 0.05rem;
        width: 0.3rem;
        left: -0.1rem;
        // margin-right: 0.1rem;
        // margin-top: -0.3rem;
        // margin: -0.5rem 0.1rem 0 -0.5rem;
      }
    }

    .rightBox {
      display: inline-block;
      box-sizing: border-box;
      width: 73%;
      height: 0.8rem;
      line-height: 0.8rem;
      padding: 0 0.2rem 0 0.5rem;
      background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/intellectualProperty/rightBoxBackground.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-size: 0.2rem;

      span {
        display: inline-block;
        line-height: 1.4;
        vertical-align: middle;
      }
    }
  }
}

.thisBoxShowMore {
  padding-left: 0.85rem;
  color: #2f74ce;
}

.kuangzhenAdvantage {
  background: none;

  .advantage {
    padding: 0 0.3rem 0 0.9rem;
    width: 98%;
    box-sizing: border-box;
    margin-bottom: 0.2rem;
  }
}
.bigBox {
  width: 90%;
  margin: 0.5rem auto;
  padding-left: 0.3rem;
  box-sizing: border-box;

  .header {
    position: relative;
    width: 100%;
    height: 3rem;
    // background-color: #f7f4f5;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/itemBG.png);
    .isHot {
      position: absolute;
      left: 0;
      top: 0;
      background-color: #fa6e23;
      padding: 0.05rem 0.3rem;
      font-family: PingFangSC-Regular, PingFang SC;
      border-radius: 0px 0px 6px 0px;
      color: #ffffff;
    }
  }

  .title {
    margin: 0.2rem 0;
    color: #707070;
    font-size: 0.35rem;
    font-weight: 700;
  }

  .msg {
    font-size: 0.3rem;
    color: #666666;
    margin-bottom: 0.1rem;
    padding: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .watchMsg {
    color: #999;
    font-size: 0.3rem;
  }
  .arrowIcon {
    position: relative;
    display: inline-block;
    top: -0.1rem;
    width: 12px;
    height: 7px;
    background-size: 100% 100%;
    background-image: url(http://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/watchMsgICon.png);
    // color: #fa6e23;
  }
}
.redIcon {
  position: relative;
  display: inline-block;
  top: -0.3rem;
  width: 8px;
  height: 7px;
  background-size: 100% 100%;
  background-image: url(http://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/redIcon.png);
}

.loadMore {
  padding: 0.3rem 0;
  text-align: center;
}
</style>
