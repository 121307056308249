<template>
  <div style="width: 95%; margin: auto">
    <div class="topmsg">
      <p style="margin-bottom: -20px">打造产品化法律服务</p>
      <p>开创“精益法律服务”先河</p>
      <p class="moneymsg">累计涉案标总金额</p>
      <p class="moneyNum">
        <countTo
          :startVal="0"
          :endVal="100000000000"
          separator=""
          :duration="2000"
        ></countTo
        >+
      </p>
    </div>
    <div class="dataList">
      <van-row>
        <van-col span="8">
          <span
            ><countTo
              :startVal="0"
              :endVal="100000"
              separator=""
              :duration="2000"
            ></countTo
            >+</span
          >
          <p>案件数量</p>
        </van-col>
        <van-col span="8">
          <span
            ><countTo
              :startVal="0"
              :endVal="6500"
              separator=""
              :duration="2000"
            ></countTo
            >+</span
          >
          <p>服务客户</p></van-col
        >
        <van-col span="8">
          <span
            ><countTo
              :startVal="0"
              :endVal="1200"
              separator=""
              :duration="2000"
            ></countTo
            >+</span
          >
          <p>在职人员</p></van-col
        >
      </van-row>
    </div>
    <div class="videoBox">
      <div class="itemBox">
        <video
          data-v-250bd9d2=""
          src="https://f.kzspp.com/cms/new/demo.mp4"
          poster="https://f.kzspp.com/cms/new/111.png"
          controls="controls"
        ></video>
        <!-- <img style="width: 100%" src="./guanyukuangzhen/001.jpg" alt="" /> -->
        <div class="text-wrapper_4">
          <span class="text_9">旷真合伙人-荆媛媛律师：</span>
        </div>
        <span class="paragraph_2"
          ><span class="text_10">当她经历从法官到律师的转身，她说: </span
          >“我没有后悔过来到旷真，即便这条路很苦很累、甚至很折磨，但是我这样一步一步走过来之后，那种破茧成蝶的成长，可能是在法院也好，或者在其他地方也好，都不会有的蜕变。<br />特别是其中的艰辛与困苦，让我不断地获得突破，成为一个技能齐全，未来发展不再受限的法律人。”</span
        >
      </div>

      <div class="itemBox">
        <video
          data-v-250bd9d2=""
          src="https://f.kzspp.com/cms/new/demo2.mp4"
          poster="https://f.kzspp.com/cms/new/222.png"
          controls="controls"
        ></video>
        <!-- <img style="width: 100%" src="./guanyukuangzhen/003.jpg" alt="" /> -->
        <div class="text-wrapper_4">
          <span class="text_9">旷真合伙人-张斌律师：</span>
        </div>
        <span class="paragraph_2">
          “我也曾面试碰壁，但是凭借着自己霸得蛮的劲头，一步步走到了现在的位置。“张斌律师用他在旷真十年的晋升故事告诉我们，能力重要，选择也同样重要。
          一个好的平台，会带给法律人的职业生涯带来无数种可能。<span
            style="color: #333; font-weight: 700"
            >所以，你准备好加入旷真了吗？哪怕“平凡”的你也能走出不平凡的路。</span
          >
        </span>
      </div>

      <div class="itemBox">
        <video
          data-v-250bd9d2=""
          src="https://f.kzspp.com/cms/new/demo3.mp4"
          poster="https://f.kzspp.com/cms/new/333.png"
          controls="controls"
        ></video>
        <!-- <img style="width: 100%" src="./guanyukuangzhen/002.jpg" alt="" /> -->
        <div class="text-wrapper_4">
          <span class="text_9">旷真合伙人-刘汝珍律师＆吴浩铨律师：</span>
        </div>
        <span class="paragraph_2">
          “法律人的明天会怎样？”“答案一定在我们这一代人身上！”两位90后律师赶上了旷真开疆拓土的2.0时代，短短几年就拼搏出自己团队。
          他们不断摆脱舒适圈，勇往直前，这是新一代青年律师的独特魅力！<span
            style="color: #333; font-weight: 700"
            >你好，后浪。
          </span>
        </span>
      </div>
      <div class="videoMsg">“语时事则指而可想，论怀抱则旷而且真。”</div>
    </div>

    <div class="jianjieBox">
      旷真律师事务所，于2009年成立于湖南长沙，目前已在北京、上海、深圳、广州、苏州、天津、南京、杭州、合肥、济南、青岛、重庆、成都、贵阳、珠海、武汉、南昌、西安、郑州、太原、沈阳、乌鲁木齐、兰州、厦门、银川、海口、惠州、东莞、无锡等城市设立分支机构，迅速成长为国内知名的法律服务领导者，拥有1000多位法律专业人士。
    </div>
    <div class="jianjieBox">
      秉持精益法律服务的使命，旷真致力于法律服务产品化、组织化和规模化，已开设
      <span style="color: #333; font-weight: 700">应收款、知识产权、民生</span>
      三大事业板块，并拥有配套的成熟法律服务产品。
      旷真始终秉持“客户中心”的服务理念，立足市场，发挥专业、经验及品牌优势，为客户合法权益保驾护航。
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
export default {
  components: {
    countTo,
  },
};
</script>

<style lang="scss" scoped>
.topmsg {
  color: #ab0000;
  text-align: center;
  padding-top: 1rem;
  font-size: 0.45rem;
  font-weight: 700;
  .moneymsg {
    font-size: 0.35rem;
    color: #333333;
    font-weight: 400;
  }
  .moneyNum {
    font-size: 0.7rem;
    margin-top: -0.4rem;
    font-weight: 500;
  }
}
.dataList {
  width: 95%;
  margin: auto;
  text-align: center;
  color: #707070;
  span {
    font-size: 0.4rem;
    font-weight: 500;
    margin-right: -3px;
  }
  p {
    font-size: 0.3rem;
    font-weight: 700;
    margin-top: 0.1rem;
    opacity: 0.3;
  }
}
.videoBox {
  width: 95%;
  margin: auto;
  video {
    width: 100%;
    height: auto;
  }
  .videoMsg {
    color: #ab0000;
    font-size: 0.4rem;
    text-align: center;
    font-weight: 700;
  }
}
.jianjieBox {
  color: #666;
  font-size: 0.35rem;
  width: 95%;
  margin: 0.5rem auto;
  line-height: 0.5rem;
  opacity: 0.8;
}

.itemBox {
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d4d1d1;

  .text-wrapper_4 {
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    margin: 0.2rem 0;
  }
  .text_9 {
    color: rgba(171, 0, 0, 1);
    font-size: 0.4rem;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
  }

  .text_10 {
    color: rgba(51, 51, 51, 1);
    font-size: 0.35rem;
    font-weight: 700;
    font-family: PingFangSC-Semibold;
  }

  .paragraph_2 {
    color: rgba(153, 153, 153, 1);
    font-size: 0.35rem;
  }

  .yushishizezhi {
    color: #ab0000;
    text-align: left;
    font-size: 0.35rem;
  }
}
</style>
