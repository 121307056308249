<template>
  <div>
    <titleBox name="新闻动态" imgUrl="../../assets/about/title.png"></titleBox>
    <div class="main" @scroll="scrollEvent($event)">
      <div
        class="bigBox"
        v-for="(item, index) in list"
        :key="index"
        @click="toMsgPage(item.id)"
      >
        <div class="header" :style="'background-image: url(' + item.img + ')'">
          <!-- <div class="isHot">Hot</div> -->
        </div>
        <div class="title">{{ item.title }}</div>
        <div class="msg">
          {{ item.summary }}
        </div>
        <div class="watchMsg">
          查看详细解读 <i name="arrow" class="arrowIcon"></i>
        </div>
      </div>

      <div class="loadMore" v-show="loadMoreFlag" @click="loadMoreNews">
        加载更多...
      </div>
    </div>
  </div>
</template>

<script>
import titleBox from "../../components/titleBox/index";
import req from "../../utils/index";
export default {
  components: { titleBox },
  data() {
    return {
      list: [],
      loadMoreFlag: true,
      pageNum: 1,
    };
  },
  methods: {
    toMsgPage(id) {
      this.$router.push("/newsMsg?id=" + id);
    },
    loadMoreNews() {
      this.pageNum = Number(this.pageNum) + 1;
      this.search();
    },
    search() {
      req("post", "api/base/articlelist", {
        Cid: "6",
        Page: this.pageNum,
        Limit: 10,
      }).then((res) => {
        this.list = this.list.concat(res.data);
        if (res.data.length < 10) {
          this.loadMoreFlag = false;
        }
      });
    },
  },
  created() {
    this.search();
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 95%;
  margin: auto;

  .bigBox {
    width: 100%;
    margin: 0.7rem auto;
    box-sizing: border-box;

    .header {
      position: relative;
      width: 100%;
      height: 5rem;
      // background-color: #f7f4f5;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/itemBG.png);
      .isHot {
        position: absolute;
        left: 0;
        top: 0;
        background-color: #fa6e23;
        padding: 0.05rem 0.3rem;
        font-family: PingFangSC-Regular, PingFang SC;
        border-radius: 0px 0px 6px 0px;
        color: #ffffff;
      }
    }

    .title {
      margin: 0.2rem 0;
      color: #707070;
      font-size: 0.35rem;
      font-weight: 700;
    }

    .msg {
      font-size: 0.2rem;
      color: #666666;
      margin-bottom: 0.1rem;
      padding: 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .watchMsg {
      color: #999;
      font-size: 10px;
      margin-top: 0.3rem;
    }
  }

  .watchMsg {
    color: #999;
  }
  .arrowIcon {
    position: relative;
    display: inline-block;
    top: -0.1rem;
    width: 12px;
    height: 7px;
    background-size: 100% 100%;
    background-image: url(http://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/watchMsgICon.png);
  }
}

.loadMore {
  padding: 0.3rem 0;
  text-align: center;
}
</style>
