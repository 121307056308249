import axios from 'axios';
import { apiServer } from "@/utils/config";
import Vue from 'vue';
import { Toast } from 'vant';
// import Router from '@/router/index'
import Store from '@/vuex/store.js'
Vue.use(Toast);
// 通用公用方法
const req = (method, url, data) => {
    return axios({
        method: method,
        url: apiServer + url,
        headers: {
            'Content-Type': 'application/json',
        },
        data,
    }).then(res => {
        return res.data
    });
};

export default req