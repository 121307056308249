// 导入组件
import Vue from 'vue';
import Router from 'vue-router';

// 启用路由
Vue.use(Router);

// 导入路由

// 首页
import index from '../pages/home/index'
import about from '../pages/about/index'
import receivables from '../pages/receivables/index'
import businessArea from '../pages/businessArea/index'
import intellectualProperty from '../pages/intellectualProperty/index'
import newsInformation from '../pages/newsInformation/index'
import joinUs from '../pages/joinUs/index'
import contactUs from '../pages/contactUs/index'
import newsMsg from '../pages/newsMsg/index'
// 导出路由 
export default new Router({
    // mode: 'history',
    routes: [
        {
            path: '/',
            name: 'index',
            component: index,
            meta: {
                title: '首页'
            }
        },
        {
            path: '/index',
            name: 'index',
            component: index,
            meta: {
                title: '首页'
            }
        },
        {
            path: '/about',
            name: 'about',
            component: about,
            meta: {
                title: '关于旷真'
            }
        },
        {
            path: '/receivables',
            name: 'receivables',
            component: receivables,
            meta: {
                title: '旷真应收款'
            }
        },
        {
            path: '/businessArea',
            name: 'businessArea',
            component: businessArea,
            meta: {
                title: '旷真民生'
            }
        },
        {
            path: '/intellectualProperty',
            name: 'intellectualProperty',
            component: intellectualProperty,
            meta: {
                title: '旷真知产'
            }
        },
        {
            path: '/newsInformation',
            name: 'newsInformation',
            component: newsInformation,
            meta: {
                title: '新闻动态'
            }
        },
        {
            path: '/joinUs',
            name: 'joinUs',
            component: joinUs,
            meta: {
                title: '加入我们'
            }
        },
        {
            path: '/contactUs',
            name: 'contactUs',
            component: contactUs,
            meta: {
                title: '联系我们'
            }
        },
        {
            path: '/newsMsg',
            name: 'newsMsg',
            component: newsMsg,
            meta: {
                title: '新闻动态'
            }
        }
    ]
})