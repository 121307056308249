<template>
  <div style="background: #fff">
    <div class="joinTopImg">
      <p class="joinTitle">致未来旷真的一封信</p>
    </div>
    <!-- 招聘邮箱 -->
    <div class="getEmail">
      <h5><van-icon name="envelop-o" class="iconfont" />招聘邮箱</h5>
      <div class="email">
        kuangzhenhr@kzspp.com
        <span style="font-size: 0.25rem">邮件名称请统一</span>
      </div>
      <div class="emailMsg">/ 旷真官网—XX地区—XX岗位应聘 /</div>
    </div>
    <div class="getEmail borderLine">
      <h5><van-icon name="phone-o" class="iconfont" />招聘电话</h5>
      <div class="email">18373120090 / 0731-89802222</div>
    </div>
    <!-- 搜索 -->
    <div class="searchBox">
      <div class="left" @click="showCity">
        {{ cityName || "地点" }}<van-icon name="play" class="addIcon" />
      </div>
      <div class="rightBox">
        <input
          type="search"
          v-model="searchValue"
          placeholder="查找岗位"
          @change="searchChange"
        />
        <span class="iconfont icon-sousuo"></span>
      </div>
    </div>
    <!-- 职位列表 -->
    <div class="zhiweiList">
      <!-- <div class="itemBox" v-for="(item, index) in list" :key="index">
        <div class="title">{{ item.title }}</div>
        <div class="msgBox">
          <span class="littleMsgBox">{{ item.fields.field_zpxx }}</span>
          <span class="littleMsgBox">{{ item.fields.field_xz }}</span>
        </div>
        <div class="workAddress">
          <van-icon name="location-o" class="iconfont" />
          工作地点： {{ item.fields.field_gzdd }}
        </div>
      </div> -->
      <van-collapse v-model="activeName" accordion>
        <van-collapse-item
          v-for="(item, index) in list"
          :key="index"
          :title="item.title"
          :name="item.id"
        >
          <div style="margin-bottom: 0.2rem" v-if="item.fields.field_gwzz">
            <div>【岗位职责】</div>
            <div style="padding-left: 0.2rem">
              {{ item.fields.field_gwzz }}
            </div>
          </div>
          <div style="margin-bottom: 0.2rem" v-if="item.fields.field_rzzg">
            <div>【任职资格】</div>
            <div style="padding-left: 0.2rem">
              {{ item.fields.field_rzzg }}
            </div>
          </div>
          <div style="margin-bottom: 0.2rem" v-if="item.fields.field_xz">
            <div>【薪资待遇】</div>
            <div style="padding-left: 0.2rem">
              {{ item.fields.field_xz }}
            </div>
          </div>
          <div style="margin-bottom: 0.2rem" v-if="item.fields.field_gzdd">
            <div>【工作地点】</div>
            <div style="padding-left: 0.2rem">
              <!-- <van-icon name="location-o" class="iconfont" /> -->
              {{ item.fields.field_gzdd }}
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <div v-show="showCityChange" class="showCityChangeCss">
      <van-picker
        title="地区选择"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </div>
  </div>
</template>

<script>
import req from "../../utils/index";
export default {
  data() {
    return {
      columns: [],
      showCityChange: false,
      cityName: "",
      searchValue: "",
      list: [],
      activeName: "",
    };
  },
  methods: {
    onConfirm(value) {
      this.cityName = value;
      this.onCancel();
    },
    onCancel() {
      // console.log("取消");
      this.showCityChange = false;
    },
    showCity() {
      this.showCityChange = true;
    },
    searchChange() {
      let parms = {
        Cid: "29,30",
        Page: 1,
        Limit: 100,
        Title: this.searchValue,
        Mixed: {
          field_gzdd: this.cityName,
        },
      };
      if (!this.cityName) {
        delete parms.Mixed;
      }
      if (!this.searchValue) {
        delete parms.Title;
      }
      req("post", "api/base/articlelist", parms).then((res) => {
        this.list = res.data;
      });
    },
  },
  created() {
    req("get", "api/base/getice", {}).then((res) => {
      this.columns = res.fslist;
    });
    this.searchChange();
  },
};
</script>

<style lang="scss" scoped>
.pos-relative {
  height: 5.4rem;
}

.joinTopImg {
  position: relative;
  display: block;
  width: 95%;
  height: 5rem;
  margin: 1.5rem auto;
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/joinUs/joinUsTopImg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .joinTitle {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.5rem 0 0rem 0.5rem;
    color: #fff;
    font-size: 0.5rem;
  }
}
.iconfont {
  position: relative;
  top: 0.1rem;
  font-size: 0.5rem;
  margin-right: 0.1rem;
  color: #333333;
}
.getEmail {
  position: relative;
  width: 90%;
  margin: 1rem auto;

  .iconfont {
    position: relative;
    top: 0.1rem;
    font-size: 0.5rem;
    margin-right: 0.1rem;
    color: #333333;
  }

  h5 {
    font-size: 0.4rem;
    color: #333333;
    margin: 0;
  }

  .email {
    font-size: 0.4rem;
    color: #333333;
    padding-left: 0.5rem;
    font-weight: 700;
  }

  .emailMsg {
    color: #666666;
    padding-left: 0.5rem;
  }
}

.borderLine {
  padding-bottom: 1rem;
}

.borderLine::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1rem;
  background-color: #d8d8d8;
  opacity: 0.2;
  content: "";
}

.searchBox {
  position: relative;
  display: flex;
  width: 90%;
  padding: 0.3rem 0;
  margin: 0.1rem auto;
  border: 0.1rem solid #dedede;
  background: #fff;
  border-radius: 5rem;

  .left {
    flex: 1.5;
    text-align: center;

    .addIcon {
      position: relative;
      top: 0.04rem;
      margin-left: 0.1rem;
      transform: rotate(90deg);
    }
  }

  .rightBox {
    position: relative;
    flex: 5;

    input {
      width: 90%;
      border: none;
      outline: none;
    }

    input::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #dedede;
    }

    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #dedede;
    }

    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #dedede;
    }

    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #dedede;
    }

    .iconfont {
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
      color: #dedede;
    }
  }
}

.zhiweiList {
  width: 90%;
  margin: 0.8rem auto;
  padding: 0.3rem 0;
  border-top: 0.07rem solid #ccc;

  .itemBox {
    margin-bottom: 0.1rem;
    border-bottom: 0.1rem solid rgba(221, 216, 216, 0.2);

    .title {
      font-size: 0.5rem;
      color: #333;
    }

    .msgBox {
      margin: 0.3rem 0;

      .littleMsgBox {
        display: inline-block;
        padding: 0.1rem 0.5rem;
        background-color: #d8d8d8;
        border-radius: 0.3rem;
        margin-right: 0.1rem;
      }
    }

    .workAddress {
      padding-bottom: 0.3rem;
      color: #333;
    }
  }
}

.showCityChangeCss {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
}
</style>
