<template>
  <div class="main" style="width: 90%; margin: auto">
    <div
      class="bigBox"
      v-for="(item, index) in list"
      :key="index"
      @click="toMsgPage(item.id)"
    >
      <div class="header" :style="'background-image: url(' + item.img + ');'">
        <!-- <div class="isHot">Hot</div> -->
      </div>
      <div class="title">{{ item.title }}</div>
      <div class="msg">
        {{ item.summary }}
      </div>
      <div class="watchMsg">查看详细解读 <i class="arrowIcon"></i></div>
    </div>
    <div class="loadMore" v-show="loadMoreFlag" @click="loadMoreNews">
      加载更多...
    </div>
  </div>
</template>

<script>
import req from "@/utils/index.js";
export default {
  data() {
    return {
      list: [],
      pageMsg: {
        total: 0,
        pageSize: 5,
        pageNum: 1,
      },
      loadMoreFlag: true,
    };
  },
  methods: {
    toMsgPage(id) {
      this.$router.push("/newsMsg?id=" + id);
    },
    search() {
      req("post", "api/base/articlelist", {
        Cid: "15",
        Page: this.pageMsg.pageNum,
        Limit: this.pageMsg.pageSize,
      }).then((res) => {
        let obj = {
          total: res.count,
          pageSize: this.pageMsg.pageSize,
          pageNum: this.pageMsg.pageNum,
        };
        this.pageMsg = obj;
        this.list = this.list.concat(res.data);
        if (res.data.length < 5) {
          this.loadMoreFlag = false;
        }
      });
    },
    loadMoreNews() {
      this.pageMsg.pageNum = Number(this.pageMsg.pageNum) + 1;
      this.search();
    },
  },
  created() {
    this.search();
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 95%;
  margin: auto;

  .bigBox {
    width: 100%;
    margin: 0.5rem 0;

    .header {
      position: relative;
      width: 100%;
      height: 5rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;

      .isHot {
        position: absolute;
        left: 0;
        top: 0;
        background-color: #fa6e23;
        padding: 0.05rem 0.3rem;
        font-family: PingFangSC-Regular, PingFang SC;
        border-radius: 0px 0px 6px 0px;
        color: #ffffff;
      }
    }

    .title {
      margin: 0.1rem 0;
      color: #707070;
      font-size: 0.4rem;
      font-weight: 700;
    }

    .msg {
      font-size: 0.3rem;
      color: #666666;
      margin-bottom: 0.1rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .watchMsg {
    color: #999;
  }
  .arrowIcon {
    position: relative;
    display: inline-block;
    top: -0.1rem;
    width: 12px;
    height: 7px;
    background-size: 100% 100%;
    background-image: url(http://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/watchMsgICon.png);
    // color: #fa6e23;
  }
}
.loadMore {
  padding: 0.3rem 0;
  text-align: center;
}
</style>
