<template>
  <div style="background: #fff">
    <titleBox
      name="旷真民生"
      imgUrl="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/title.png"
    ></titleBox>
    <!-- <van-tabs v-model="active" title-active-color="ab0000" :sticky="true">
      <van-tab
        v-for="(item, index) in activeList"
        :title="item.title"
        :key="index"
        style="background-color: #fff"
      >
        <div slot="title">
          <a :href="'#' + item.id">{{ item.title }}</a>
        </div>
      </van-tab>
    </van-tabs> -->
    <div class="main">
      <!-- 业务领域 -->
      <div class="itemBox" id="yewu">
        <h2 class="titleBox">
          <img
            src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/01.png"
            class="numberImg"
            alt=""
          />
          旷真民生
          <i class="arrowIcon redIcon"></i>
        </h2>
        <div class="mainBox" style="">
          <img
            src="./mingsheng/01.jpg"
            style="width: 100%; margin-top: 0.2rem"
            alt=""
          />
          <!-- <div class="twoBoxFather">
            <div class="twoBox">
              <div class="littleBox">
                <img
                  src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/businessArea/hetong.png"
                  alt=""
                />
                合同纠纷
              </div>
            </div>
            <div class="twoBox">
              <div class="littleBox">
                <img
                  src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/businessArea/yichan.png"
                  alt=""
                />
                遗产继承纠纷
              </div>
            </div>
          </div>
          <div class="twoBoxFather">
            <div class="twoBox">
              <div class="littleBox">
                <img
                  src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/businessArea/fangchan.png"
                  alt=""
                />
                房产纠纷
              </div>
            </div>
            <div class="twoBox">
              <div class="littleBox">
                <img
                  src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/businessArea/mingjianjiedai.png"
                  alt=""
                />
                民间借贷纠纷
              </div>
            </div>
          </div>
          <div class="twoBoxFather">
            <div class="twoBox">
              <div class="littleBox">
                <img
                  src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/businessArea/hunying.png"
                  alt=""
                />
                婚姻家事纠纷
              </div>
            </div>
            <div class="twoBox">
              <div class="littleBox">
                <img
                  src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/businessArea/xingshi.png"
                  alt=""
                />
                刑事辩护
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- 服务流程 -->
      <div class="itemBox otherBg" id="fuwuLine">
        <h2 class="titleBox">
          <img
            src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/02.png"
            class="numberImg"
            alt=""
          />
          民生法律服务
          <i class="arrowIcon redIcon"></i>
        </h2>
        <div class="mainBox" style="">
          <img src="./mingsheng/02.jpg" style="width: 100%" alt="" />
          <img src="./mingsheng/02-1.jpg" style="width: 100%" alt="" />
          <img src="./mingsheng/02-2.jpg" style="width: 100%" alt="" />
          <img src="./mingsheng/02-3.jpg" style="width: 100%" alt="" />
          <img src="./mingsheng/02-4.jpg" style="width: 100%" alt="" />
          <img src="./mingsheng/02-5.jpg" style="width: 100%" alt="" />
          <img src="./mingsheng/02-6.jpg" style="width: 100%" alt="" />
          <img src="./mingsheng/02-7.jpg" style="width: 100%" alt="" />
          <img src="./mingsheng/02-8.jpg" style="width: 100%" alt="" />
          <!-- <div class="threeBoxFather">
            <div class="threeBox">
              <img
                src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/businessArea/zaixianRed.png"
                alt=""
              />
              <span class="littleTitle">在线咨询</span>
              <p class="msg">24小时，一对一服务，电话解答</p>
            </div>
            <div class="threeBox">
              <img
                src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/businessArea/flagRed.png"
                alt=""
              />
              <span class="littleTitle">律师会谈</span>
              <p class="msg">专家律师会谈专项制定法律方案</p>
            </div>
            <div class="threeBox">
              <img
                src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/businessArea/hetongRed.png"
                alt=""
              />
              <span class="littleTitle">签订合同</span>
              <p class="msg">确定委托方案建立专门律师小组</p>
            </div>
          </div>
          <div class="threeBoxFather">
            <div class="threeBox">
              <img
                src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/businessArea/anjianRed.png"
                alt=""
              />
              <span class="littleTitle">案件办理</span>
              <p class="msg">立案、保全、调解、出庭、执行等</p>
            </div>
            <div class="threeBox">
              <img
                src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/businessArea/zhiliangRed.png"
                alt=""
              />
              <span class="littleTitle">质量监管</span>
              <p class="msg">每个阶段有专门部门监控办案质量</p>
            </div>
            <div class="threeBox">
              <img
                src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/businessArea/kehuRed.png"
                alt=""
              />
              <span class="littleTitle">客户服务</span>
              <p class="msg">全程有专业客户律师跟进服务体验</p>
            </div>
          </div> -->
        </div>
      </div>
      <!-- 服务团队 -->
      <div class="itemBox" id="fuwuTeam">
        <h2 class="titleBox">
          <img
            src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/03.png"
            class="numberImg"
            alt=""
          />
          民生团队
          <i class="arrowIcon redIcon"></i>
        </h2>
        <div class="mainBox">
          <img src="./mingsheng/03.jpg" style="width: 100%" alt="" />
          <!-- <div class="boxItem khlstd">
            <h5 class="title">客户律师团队</h5>
            <div class="msg">
              (负责客户沟通和全程管理工作)
              <br />
              迅速准确了解客户需求、及时协调全部团队制定达成客户满意度的服务方案。
            </div>
          </div>
          <div class="boxItem shislstd">
            <h5 class="title">实施律师团队</h5>
            <div class="msg">
              (负责诉前的立案和保全工作)
              <br />
              专职负责法院流程工作，确保依据办案方案实施高质高效的诉前准备。
            </div>
          </div>
          <div class="boxItem sslstd">
            <h5 class="title">诉讼律师团队</h5>
            <div class="msg">
              (制定符合客户需求的诉讼方案)：
              <br />
              诉讼团队包含具备多年司法执业经验的高级律师，负责诉讼方案制定、庭前准备和出庭。
            </div>
          </div>
          <div class="boxItem zxlstd">
            <h5 class="title">执行律师团队</h5>
            <div class="msg">
              (负责案件监督和法院执行)：
              <br />
              负责回款的监督和催办，确保提供高效有力的诉讼结果执行。
            </div>
          </div>
          <div class="boxItem qgzjzx">
            <h5 class="title">全国专家中心</h5>
            <div class="msg">
              （负责疑难复杂案件诉讼方案的制定）
              <br />
              精通疑难复杂案件的处理，能迅速为重大民商事复杂案件提供全方面的解决方案。
            </div>
          </div> -->
        </div>
      </div>
      <!-- 服务理念 -->
      <div class="itemBox otherBg" id="fuwuIdea">
        <h2 class="titleBox">
          <img
            src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/numberImg/04.png"
            class="numberImg"
            alt=""
          />
          民生经典案例
          <i class="arrowIcon redIcon"></i>
        </h2>
        <div class="mainBox" style="padding-left: 0.2rem">
          <p class="serveMsg">
            每年案件办理超过5000+，涉及家事、刑事领域的各个细分场景，产生了大量成功维护客户权益的经典案例。
          </p>
          <div
            class="bigBox"
            v-for="(item, index) in list"
            :key="index"
            @click="toMsgPage(item.id)"
          >
            <div
              class="header"
              :style="'background-image: url(' + item.img + ')'"
            >
              <!-- <div class="isHot">Hot</div> -->
            </div>
            <div class="title">{{ item.title }}</div>
            <div class="msg">
              {{ item.summary }}
            </div>
            <div class="watchMsg">查看详细解读 <i class="arrowIcon"></i></div>
          </div>
          <div class="loadMore" v-show="loadMoreFlag" @click="loadMoreNews">
            加载更多...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleBox from "../../components/titleBox/index";
import req from "../../utils/index";
export default {
  components: { titleBox },
  data() {
    return {
      active: 0,
      activeList: [
        { title: "业务领域", id: "yewu" },
        { title: "服务流程", id: "fuwuLine" },
        { title: "服务团队", id: "fuwuTeam" },
        { title: "服务理念", id: "fuwuIdea" },
      ],
      loadMoreFlag: true,
      pageNum: 1,
      list: [],
    };
  },
  methods: {
    toMsgPage(id) {
      this.$router.push("/newsMsg?id=" + id);
    },
    toMaodian(id) {
      this.boxid = id;
    },
    loadMoreNews() {
      this.pageNum = Number(this.pageNum) + 1;
      this.search();
    },
    search() {
      req("post", "api/base/articlelist", {
        Cid: "47",
        Page: this.pageNum,
        Limit: 5,
      }).then((res) => {
        this.list = this.list.concat(res.data);
        if (res.data.length < 5) {
          this.loadMoreFlag = false;
        }
      });
    },
  },
  created() {
    this.search();
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #333;
}
.itemBox {
  // width: 95%;
  margin: 0rem auto;
  padding: 0.3rem 0;
  background-color: #fff;

  .titleBox {
    width: 95%;
    margin: auto;
    position: relative;
    height: 1rem;
    line-height: 1rem;
    font-size: 0.5rem;

    .numberImg {
      position: relative;
      top: 0.05rem;
      width: 0.5rem;
      height: 0.5rem;
    }
  }
}

.mainBox {
  width: 95%;
  margin: auto;
  padding-top: 0.1rem;

  .twoBoxFather {
    overflow: hidden;

    .twoBox {
      float: left;
      width: 50%;
      text-align: center;

      .littleBox {
        display: inline-block;
        width: 85%;
        padding: 0.2rem 0rem;
        background-color: #f7f7f7;
        border-radius: 0.2rem;
        margin-bottom: 0.2rem;
        color: #333;
        font-weight: 700;

        img {
          width: 0.4rem;
          position: relative;
          top: 0.06rem;
          margin: 0rem 0.3rem 0 0;
        }
      }
    }
  }

  .threeBoxFather {
    width: 100%;
    margin: auto;
    overflow: hidden;
    padding: 0.5rem 0;
    text-align: center;

    .threeBox {
      position: relative;
      float: left;
      width: 30%;
      margin-left: 0.2rem;
      background-color: #fff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
      border-radius: 0.2rem;

      img {
        position: absolute;
        left: 0;
        top: -0.1rem;
        width: 0.8rem;
      }

      padding: 0.4rem 0 0.12rem 0;
      text-align: center;

      .littleTitle {
        position: relative;
        color: #333333;
        font-weight: 700;
        z-index: 999;
      }

      .littleTitle::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.2rem;
        content: "";
        background: linear-gradient(
          226deg,
          rgba(218, 0, 0, 0) 0%,
          #da0000 100%
        );
        opacity: 0.3;
      }

      .msg {
        width: 75%;
        margin: 0.3rem auto;
        text-align: center;
        color: #666666;
      }
    }
  }

  .boxItem {
    width: 95%;
    padding: 0.1rem 0.5rem 0.3rem 0.3rem;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #fff;
    margin: 0.3rem 0 0.3rem 0.6rem;
    font-size: 0.1rem;

    .title {
      font-size: 0.4rem;
      margin: 0.1rem 0;
    }
  }

  .serveMsg {
    color: #666666;
    padding-left: 0.5rem;
    box-sizing: border-box;
    margin: -0rem 0 0.3rem 0;
    line-height: 0.5rem;
  }

  .lastTitle {
    font-family: FZQKBYSJW--GB1-0, FZQKBYSJW--GB1;
    color: #ab0000;
    padding-left: 0.5rem;
  }
}

.otherBg {
  background-color: #f1f1f1;
}

.arrowIcon {
  position: relative;
  font-size: 0.1rem;
  top: -0.15rem;
  color: #fa6e23;
}
.khlstd {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/businessArea/khlstd.png);
}
.shislstd {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/businessArea/shislstd.png);
}
.sslstd {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/businessArea/sslstd.png);
}
.zxlstd {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/businessArea/zxlstd.png);
}
.qgzjzx {
  background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/businessArea/qgzjzx.png);
}
.redIcon {
  position: relative;
  display: inline-block;
  top: -0.3rem;
  width: 8px;
  height: 7px;
  background-size: 100% 100%;
  background-image: url(http://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/redIcon.png);
}

.bigBox {
  width: 90%;
  margin: 0.5rem auto;
  padding-left: 0.3rem;
  box-sizing: border-box;

  .header {
    position: relative;
    width: 100%;
    height: 5rem;
    // background-color: #f7f4f5;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    background-image: url(https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/itemBG.png);
    .isHot {
      position: absolute;
      left: 0;
      top: 0;
      background-color: #fa6e23;
      padding: 0.05rem 0.3rem;
      font-family: PingFangSC-Regular, PingFang SC;
      border-radius: 0px 0px 6px 0px;
      color: #ffffff;
    }
  }

  .title {
    margin: 0.2rem 0;
    color: #707070;
    font-size: 0.35rem;
    font-weight: 700;
  }

  .msg {
    font-size: 0.3rem;
    color: #666666;
    margin-bottom: 0.1rem;
    padding: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .watchMsg {
    color: #999;
    font-size: 0.3rem;
  }
  .arrowIcon {
    position: relative;
    display: inline-block;
    top: -0.1rem;
    width: 12px;
    height: 7px;
    background-size: 100% 100%;
    background-image: url(http://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/watchMsgICon.png);
    // color: #fa6e23;
  }
}
.loadMore {
  padding: 0.3rem 0;
  text-align: center;
}
</style>
