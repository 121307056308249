<template>
  <div>
    <div class="bigBox">
      <h3>{{ name }}</h3>
      <img
        src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/abuout/serve.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    name: { default: "" },
    imgUrl: { default: "" },
  },
};
</script>

<style lang="scss" scoped>
.bigBox {
  width: 100%;
  padding: 15% 0 7% 0;
  background-image: url(../../assets/zhichanBg.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  h3 {
    color: #fff;
    text-align: center;
    font-size: 0.55rem;
    font-weight: 400;
  }
}
</style>
