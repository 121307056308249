<template>
  <div style="background-color: #fff">
    <div class="main">
      <h3 class="newsTitle">{{ info.title }}</h3>
      <p class="littleTitle">{{ info.summary }}</p>
      <p class="newsMsg">
        发布时间：{{ info.time }} <br />
        来源：{{ info.source }} <br />
        编辑：{{ info.examine }}
      </p>
      <p v-html="content"></p>
      <!-- <img
        src="https://kz-images.oss-cn-shenzhen.aliyuncs.com/kzweb/Mobile/newsMsg/2.png"
        alt=""
        class="imgCss"
      /> -->
    </div>
  </div>
</template>

<script>
import req from "@/utils/index.js";
export default {
  data() {
    return {
      info: {},
      content: "",
    };
  },
  methods: {
    initeData(data) {
      let nodes = data.content;
      const regex = new RegExp("<img", "gi");
      const space = new RegExp("<p>&crarr;</p>", "gi");
      const space1 = new RegExp("section", "gi");
      const space2 = new RegExp("<p", "gi");
      const space3 = new RegExp("↵", "gi");
      nodes = nodes.replace(regex, `<img style="max-width: 100%;"`);
      nodes = nodes.replace(space, "");
      nodes = nodes.replace(space1, "p");
      nodes = nodes.replace(space2, `<p style="margin-top:15px"`);
      nodes = nodes.replace(space3, "");
      console.log(nodes);
      this.content = nodes;
    },
  },
  created() {
    let id = this.$route.query.id;
    req("get", "api/base/getcontent?zd=1&id=" + id, {}).then((res) => {
      //   console.log(res);
      this.initeData(res.info);
      res.info.time = res.info.time.substring(0, 10);
      this.info = res.info;
    });
  },
};
</script>

<style scoped>
.littleTitle {
  color: #999;
}
.main {
  width: 90%;
  margin: 0 auto;
}
.newsMsg {
  font-size: 0.4rem;
  color: #999;
  margin-bottom: 0.4rem;
}

.paragraph {
  color: #666666;
  font-size: 0.3rem;
}

.imgCss {
  width: 100%;
}
</style>
