import Vue from 'vue'
import App from './App.vue'
// 引入路由
import router from './router';
// 引入状态管理
import store from './vuex/store';

// rem
import 'lib-flexible/flexible.js'

// 字体
import './assets/font/font.css'

// vant组件
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

// AmapVue.config.key = '22d29933d169a5ca9d02c9e3589cb3b5'
// Vconsole
// import Vconsole from 'vconsole';
// new Vconsole();

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  store, //使用store vuex状态管理
  render: h => h(App),
}).$mount('#app')
