<template>
  <div id="app">
    <topBox v-show="flag"></topBox>
    <!-- <div style="display: none">
      <el-amap vid="amap" :plugin="plugin" class="amap-demo" :center="center">
      </el-amap>
    </div> -->

    <router-view />

    <!-- <tabbar v-show="tabbarShow" /> -->
  </div>
</template>

<script>
import topBox from "./components/topBox/index";
export default {
  name: "App",
  components: { topBox },
  created() {
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    if (isMobile) {
      // 如果是移动端，跳转到移动端链接
      // window.location.href = 'https://mobi.kuangzhen.com.cn';
    } else {
      // 如果是PC端，跳转到PC端链接
      window.location.href = "https://www.kuangzhen.com.cn";
    }
  },
  data() {
    return { flag: true };
  },
  methods: {},
  watch: {
    "$route.path": function (newVal, oldVal) {
      if (newVal === "/newsMsg") {
        this.flag = false;
      } else {
        this.flag = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  padding: 0;
  margin: 0;
  background-color: #f7f8fa;
  width: 100vw;
  overflow: hidden;
}
</style>
