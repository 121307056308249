<template>
  <div>
    <van-swipe class="bannerCss" :autoplay="3000" indicator-color="#007aff">
      <van-swipe-item>
        <img src="../../assets/index/ban01.png" alt="" />
      </van-swipe-item>
      <van-swipe-item>
        <img src="../../assets/index/ban02.png" alt="" />
      </van-swipe-item>
      <van-swipe-item>
        <img src="../../assets/index/ban03.png" alt="" />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgList: [
        "../../assets/index/ban01.png",
        "../../assets/index/ban02.png",
        "../../assets/index/ban03.png",
      ],
    };
  },
  methods: {
    change() {
      this.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.bannerCss {
  width: 100%;
  height: 100vh;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
