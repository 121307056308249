// let hostName = 'http://192.168.5.107:8080' // 本地
let hostName = 'https://zsy-dev.tsycvip.com' // 测试
// let hostName = 'http://192.168.5.109:8080' // 正式

// let apiServer = 'http://192.168.5.108:8801/api' // 本地
let apiServer = 'https://cms.kzspp.com/' // 测试
// let apiServer = 'http://192.168.5.108:8801/api' // 正式

export {
    hostName,
    apiServer,
}