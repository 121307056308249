<template>
  <div style="background-color: #fff">
    <img src="./map.png" alt="" class="kuangzhenMap" />
    <div class="main">
      <h4 class="jianjie">
        旷真规模化建设的同时，不断思考和研究新一代法律服务产品，将精益法律服务推向更多的行业。
      </h4>
      <h5 class="haveCity">
        已有城市：北京、上海、深圳、广州、长沙、苏州、天津、南京、杭州、合肥、济南、青岛、重庆、成都、贵阳、珠海、武汉、南昌、西安、郑州、太原、沈阳、乌鲁木齐、兰州、厦门、银川、海口、惠州、东莞、无锡
      </h5>
      <div class="bigBox">
        <div class="itemBox" v-for="(item, index) in list" :key="index">
          <div class="leftBox">
            <img :src="item.img" alt="" />
          </div>
          <div class="rightBox">
            <div class="title">{{ item.title }}</div>
            <div class="address">
              <van-icon name="location-o" class="iconfont" />
              {{ item.fields.field_address }}
            </div>
            <div class="address phone" v-show="item.fields.field_tel">
              <van-icon name="phone-o" class="iconfont" />
              {{ item.fields.field_tel }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import req from "@/utils/index.js";
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    req("post", "api/base/articlelist", {
      Cid: "8",
      Page: 1,
      Limit: 999,
    }).then((res) => {
      this.list = res.data;
    });
  },
};
</script>

<style lang="scss" scoped>
.kuangzhenMap {
  width: 100%;
  margin: 2rem auto 0rem auto;
}
.pos-relative {
  height: 5.4rem;
}

.main {
  width: 95%;
  margin: 0rem auto 0;
  padding-bottom: 1rem;
  background-color: #fff;

  .bigBox {
    width: 100%;
    background-color: #fff;
    margin-top: 1rem;

    .itemBox {
      display: flex;
      width: 100%;
      height: 2rem;
      margin: 0.5rem auto;

      .leftBox {
        flex: 3;
        // background-color: #D8D8D8;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .rightBox {
        flex: 5;
        padding-left: 0.3rem;

        .title {
          font-size: 0.3rem;
          padding: 0rem 0 0.01rem 0;
          color: #ab0000;
          vertical-align: top;
        }

        .address {
          position: relative;
          font-size: 0.3rem;
          padding: 0.05rem 0 0 0.5rem;

          .iconfont {
            position: absolute;
            top: 0.05rem;
            left: 0;
            font-size: 0.4rem;
            color: #999999;
          }
        }
      }
    }
  }
}
.jianjie {
  color: #ab0000 !important;
  font-size: 14px;
  margin: auto;
}

.haveCity {
  margin: 30px auto 0 auto;
}
</style>
