<template>
  <div>
    <div class="topbox" :style="show ? 'background-color: #fff;' : ''">
      <div class="leftBox">
        <img src="../../assets/logo.png" v-show="!show" alt="" />
        <img src="../../assets/topLogo.png" v-show="show" alt="" />
      </div>
      <div class="rightBox" @click="show = !show">
        <img src="../../assets/set.png" v-show="!show" alt="" />
        <img src="../../assets/navBtn.png" v-show="show" alt="" />
      </div>
    </div>
    <van-popup
      class="navBox"
      @click.stop="show = false"
      v-model="show"
      position="right"
      :overlay="false"
    >
      <ul class="nav">
        <li @click.stop="changerouter(1)" :class="id == 1 ? 'active' : ''">
          首页
        </li>
        <li @click.stop="changerouter(2)" :class="id == 2 ? 'active' : ''">
          关于旷真
        </li>
        <li @click.stop="changerouter(3)" :class="id == 3 ? 'active' : ''">
          旷真应收款
        </li>
        <li @click.stop="changerouter(4)" :class="id == 4 ? 'active' : ''">
          旷真民生
        </li>
        <li @click.stop="changerouter(5)" :class="id == 5 ? 'active' : ''">
          旷真知产
        </li>
        <li @click.stop="changerouter(6)" :class="id == 6 ? 'active' : ''">
          新闻动态
        </li>
        <li @click.stop="changerouter(7)" :class="id == 7 ? 'active' : ''">
          加入旷真
        </li>
        <li @click.stop="changerouter(8)" :class="id == 8 ? 'active' : ''">
          联系我们
        </li>
      </ul>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      id: 1,
    };
  },
  methods: {
    changerouter(id) {
      this.id = id;
      switch (id) {
        case 1:
          this.$router.push("/index");
          break;
        case 2:
          this.$router.push("/about");
          break;
        case 3:
          this.$router.push("/receivables");
          break;
        case 4:
          this.$router.push("/businessArea");
          break;
        case 5:
          this.$router.push("/intellectualProperty");
          break;
        case 6:
          this.$router.push("/newsInformation");
          break;
        case 7:
          this.$router.push("/joinUs");
          break;
        case 8:
          this.$router.push("/contactUs");
          break;
      }
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped>
.topbox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 15px 0.5rem;
  box-sizing: border-box;
  overflow: hidden;
  height: 50px;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.3);
  .leftBox {
    float: left;
    width: 2rem;
    img {
      width: 80%;
    }
  }
  .rightBox {
    float: right;
    width: 0.3rem;
    height: 0.3rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.navBox {
  margin-top: 35px;
  width: 100vw;
  height: calc(100vh - 20px);
  .nav {
    li {
      padding: 0.3rem 0.3rem 0.3rem 0.6rem;
      font-size: 0.35rem;
      color: #333;
      font-weight: 400;
      font-family: PingFangSC-Regular, PingFang SC;
    }
    li.active {
      color: #ab0000;
      font-weight: 700;
    }
  }
}
</style>
